
import { defineComponent, computed } from "vue";
import ParamView from "./components/ParamView.vue";

export default defineComponent({
  components: { ParamView },
  setup() {
    const pwaContextScreen = require("./assets/pwa_install_screen_context.png");
    const pwaConfirmScreen = require("./assets/pwa_install_screen_confirm.png");

    const currentUrl = new URL(location.href);
    let params = new URLSearchParams(currentUrl.search);
    for (const [key, value] of params.entries()) {
      params = { ...params, [key]: value };
    }

    const emptyParams = computed(() => {
      return Object.entries(params).length === 0;
    });

    return { params, emptyParams, pwaContextScreen, pwaConfirmScreen };
  }
});
